<template>
    <div class="mb-5 pb-5">
        <TitleHeader :title="title" />

        <div class="search_input px-3 position-relative mb-3">
            <div class="input-group">
                <span class="input-group-text border border-end-0 text-body border" id="basic-addon1"><i class="far fa-search"></i></span>
                <input type="search" class="form-control border border-start-0 ps-0" placeholder="질문 검색" aria-describedby="basic-addon1" v-model="search" v-on:keyup.enter="searchFn()">
            </div>
        </div>
        <div class="mx-3">
            <ul class="board_list" v-if="faq&&faq.length>0">
                <li v-for="(item,index) in faq" :key="index">
                    <div class="txt_box d-flex w-100 align-items-center border-bottom py-3 pe-2" @click="open_faq_idx == item.code ? open_faq_idx=-1 : open_faq_idx = item.code">
                        <b class="me-2 pe-1">Q.</b>
                        <p class="tit ">{{item.title}}</p>
                        <i class="fal small ps-3 ms-auto" :class="{'fa-chevron-down':open_faq_idx != item.code, 'fa-chevron-up':open_faq_idx == item.code}"></i>
                    </div>
                    <div class="txt_box small px-3 py-5 bg-gray-relative-100 border-bottom" v-if="open_faq_idx == item.code">
                        {{item.memo}}
                    </div>
                </li>
            </ul>  
            <div class="empty_data" v-else>
                <p class="text-center py-5">
                    준비중입니다.
                </p>
            </div>  
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '자주 찾는 질문',

            faq: [],

            search:'',

            // front ui
            open_faq_idx: -1,
        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        // this.GetFaqList();
        this.GetList();
    },
    methods: {
        // GetFaqList(){
        //     alert("??")
        // },
        searchFn(){
            this.open_faq_idx = -1;
            this.GetList();
        },
        GetList(){
            const search = this.search;
            const body = {search};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/cs/GetFaqList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            
                            this.faq = body.list
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );
        },
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    }
}
</script>
