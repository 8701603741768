<template>
    <div class="list pb-nav">
        <TitleHeader :title="title"  link="/my"/>

        <ul class="basic_list d-flex flex-wrap flex-column p-3" v-if="notice_list&&notice_list.length>0">
            <li v-for="(item,index) in notice_list" :key="index" class="d-flex justify-content-between mb-3 border-bottom pb-3">
                <router-link :to="`/cs/notice/${item.code}`" class="left d-flex w-100">
                    <div class="d-flex flex-column justify-content-center">
                        <span class="text-truncate">{{ item.title }}</span>
                        <small class="text-body text-opacity-50"><span class="small">{{ item.date }}</span></small>
                    </div>
                </router-link>
            </li>
        </ul>
        
        <div class="empty_data" v-else>
            <p class="text-center py-5">
                게시글이 없어요.
            </p>
        </div>
    </div>
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import TitleHeader from '@/components/common/TitleHeader.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        BasicList,
        TitleHeader
    },
    data(){
        return{
            title:'공지사항',

            notice_list: []
        }
    },
    computed:{
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.GetNoticeList()
    },
    methods:{
        GetNoticeList(){
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/cs/GetNoticeList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            
                            this.notice_list = body.list
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );
        },
    },
    
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>
<style lang="scss" scoped>
</style>