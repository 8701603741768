<template>
    <div class="notice list pb-nav">
        <TitleHeader :title="title" />
        <div class="d-flex flex-column py-3 px-3 mb-3">
            <h6 class="mb-1">{{info.title}}</h6>
            <small class="mb-4 text-opacity-50 text-body">{{info.date}}</small>
            <div class="small desc white-space-pre-line" v-html="info.memo"></div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'공지사항',
            code : this.$route.params.code,
            info : {title : '',memo : '',date : ''}
        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.GetNoticeDetail();
    },
    methods:{
        GetNoticeDetail(){
            const code = this.code
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/cs/GetNoticeDetail',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            
                            this.info = body.info
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );
        },
    },
    
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>
<style lang="scss">
.notice{
    .desc{
        img{
            display: block;
            width: 100%;
            max-width: 600px;
        }
    }
}
</style>